exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ladyverse-tsx": () => import("./../../../src/pages/ladyverse.tsx" /* webpackChunkName: "component---src-pages-ladyverse-tsx" */),
  "component---src-pages-media-tsx": () => import("./../../../src/pages/media.tsx" /* webpackChunkName: "component---src-pages-media-tsx" */),
  "component---src-pages-test-page-mdx": () => import("./../../../src/pages/test-page.mdx" /* webpackChunkName: "component---src-pages-test-page-mdx" */),
  "component---src-pages-trixieverse-tsx": () => import("./../../../src/pages/trixieverse.tsx" /* webpackChunkName: "component---src-pages-trixieverse-tsx" */)
}

